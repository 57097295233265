

/* PRODUCT COMPONENT */
.products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 3vh 0 10vh 0;
    height: 90vh;
    width: 85%;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.products-container::-webkit-scrollbar {
  /*Chrome, Safari, Edge*/
  display: none;
}

.products-title-container {
    display: flex;
    justify-content: center; /* Center the title */
    margin-bottom: 20px; /* Add some space between title and products */
}

.products-content-container {
    display: flex;
    flex-wrap: wrap; /* Allow products to wrap to new rows */
    justify-content: center; /* Center the products horizontally */
}

.product-paper {
    max-width: 300px;
    min-width: 300px;
    padding: 20px;
    margin: 20px;
    background-color: var(--highlight) !important;
    position: relative;
    cursor: pointer;
}

.product-paper:hover {
    /* background-color: var(--highlight-hover) !important; */
    transform: scale(1.03);
    transition: transform 0.2s ease;
}

.price-container {
    position: absolute;
    bottom: 10px; /* Adjust as needed */
    left: 25px; /* Adjust as needed */
    padding: 2px 15px;
    border-radius: 5px;
}

.add-to-cart-container {
    position: absolute;
    bottom: 10px; /* Adjust as needed */
    right: 25px; /* Adjust as needed */
}

.add-to-cart-btn {
    color: #fff !important;
    background-color: var(--shadow) !important;
    z-index: 5 !important;
}


.add-to-cart-btn:hover {
    background-color: var(--accent-color) !important;
    transition: background-color 0.1s ease;
}


/* PRODUCT DETAILS */
.product-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 5vh;
    height: 90vh;
    width: 90%;
    overflow: scroll;
    scrollbar-width: none;
    padding-bottom: 10vh;
}

.product-details-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    font-size: 2rem;
    border-radius: 25px;
    padding-bottom: 50px;
    width: 100%;
}

.product-details-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 1vw;
}

.product-details-back-btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 15px 0 10px 20px;
}

.product-details-back-btn {
    background-color: #fff !important;
    color: var(--text-color) !important;
    box-shadow: none !important;
}

.product-details-back-btn:hover {
    background-color: #fff !important;
    transition: background-color 0.1s ease;
}

.product-details-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 30px;
}

.product-details-cart-btn {
    margin-top: 20px;
    background-color: var(--shadow) !important;
}

.product-details-cart-btn:hover {
    background-color: var(--accent-color) !important;
    transition: background-color 0.1s ease;
}

.product-details-image-container {
    flex: 2.25;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    margin: 0 2vw;
    min-height: 90%;
    border-radius: 25px;
    flex-direction: row;
}

.product-image-container {
    display: flex;
    flex: 4;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-width: 70%;
    padding: 20px;
}

.product-image {
    object-fit: cover;
    border-radius: 25px;
    max-width: 100%;
}

.product-details-extra-images-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 5px;
}

.product-details-extra-image {
    margin: 5px 0;
    object-fit: cover;
    border-radius: 25px;
    cursor: pointer;
    transition: transform 0.2s ease;
    max-width: 100%;
}

.product-details-extra-image:hover {
    transform: scale(1.03);
}

.product-details-title {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
}

.product-details-price {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    color: var(--text-color);
}

@media screen and (max-width: 1440px) {
    .product-details-image-container {
        flex-direction: column;
    }
    .product-details-extra-images-container {
        flex-direction: row;
        justify-content: space-evenly;
    }
    .product-details-extra-image {
        width: 30%;
    }
}

@media screen and (max-width: 768px) {
    .products-container {
        padding-bottom: 10vh;
    }

    .products-title-container {
        margin: 0px;
    }

    .product-paper {
        width: calc(85% - 40px);
    }

    .product-page {
        justify-content: center;
        align-items: center;
        padding-top: 2.5vh;
    }

    .product-image-container {
        flex: 0;
    }

    .product-details-extra-images-container {
        flex: 0;
    }

    

    .product-details-container {
        width: 90%;
        margin: 0;
        overflow-y: scroll;
    }

    .product-details-content {
        flex-direction: column;
    }

    .product-details-title {
        justify-content: center;
        text-align: center;
    }

    .product-details-price {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .product-details-item {
        padding: 0;
        align-items: center;
    }

    .product-details-image-container {
        margin: 0 2vw;
        min-height: 0;
    }

    .product-image {
        width: 100%;
    }
    
}