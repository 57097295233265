
.cart-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 80vh;
    padding-top: 5vh;
    width: 80%;
    flex-direction: column;
}

.cart-content {
    position: relative; /* Make cart-content position:relative */
    min-height: 100%; /* Ensure it takes up at least the height of its parent */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-content-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: medium;
    color: var(--text-color);
    margin: 0 0 30px 20px;
}

.cart-content-top {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 70%;
    width: 105%;
    margin-bottom: 40px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
}

.cart-content-footer {
    position: absolute; /* Position the footer absolutely */
    display: flex;
    bottom: 0; /* Stick it to the bottom */
    align-items: center;
    width: 80%;
    font-size: medium;
    color: var(--text-color);
    opacity: 0.8;
}
.cart-total-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.cart-total {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 10%;
    padding: 20px;
    margin-right: 10px;
}

.cart-back-btn {
    background-color: transparent !important;
    color: var(--text-color) !important;
    box-shadow: none !important;    
}

/* ITEM-COMPONENT */
.cart-item-root {
    width: 85%;
    height: 100px;
    padding: 20px;
}

.cart-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: 120px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
}

.cart-item-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.cart-item-details {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: inherit;
    padding: 20px;
}

.item-info-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
}

.item-title {
    font-size: 16px;
    color: var(--text-color);
    margin-bottom: 10px;
}

.item-detail {
    font-size: 14px;
    color: var(--text-color);
    opacity: 0.7;
}

.item-quantity-button {
    border: none;
    background-color: transparent;
    color: var(--text-color);
    font-size: 20px;
    cursor: pointer;
}

.item-quantity-button:active {
    transform: scale(0.9);
    opacity: 0.5;
}

.item-quantity-container {
    display: flex;
    justify-content: inherit;
    align-items: center;
    flex-direction: row;
    width: 80px;
    min-width: 80px;
    height: 35px;
    border: 1px solid var(--text-color);
    border-radius: 20px;
    overflow: hidden;
    opacity: 0.7;
}

.item-quantity {
    font-size: 16px;
    color: var(--text-color);
}

.item-price-text {
    font-size: 24px;
    color: var(--text-color);
    margin-right: 20px;
    opacity: 0.7;
}

.remove-item {
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 20px;
    color: var(--text-color);
    background-color: transparent;
    border: none;
    opacity: 0.7;
}

.checkout-button {
    height: 50px;
    width: 200px;
    background-color: var(--shadow) !important;
    border-radius: 0 0 4px 4px;
    color: white !important;
    border: 0;
    font-weight: 600 !important;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.4);
    animation: bounce 1.5s infinite;
    background-color: var(--shadow) !important;
}

.checkout-button:hover {
    background-color: var(--accent-color) !important;
    transition: background-color 0.3s;
    animation: none;

}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

.checkout-button-container {
    display: flex;
}

/* CHECKOUT-COMPONENT */

.checkout-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 90%;
    margin-top: 20px;
    flex: 1;
}

.checkout-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
}

@media (max-width: 768px) {
    .cart-container {
        width: 100%;
    }
    .cart-content {
        width: 100%;
    }
    .cart-content-top {
        width: 100%;
    }
    .cart-total-container {
        width: 100%;
    }
    .cart-total {
        width: 100%;
    }
    .cart-item-root {
        width: 94%;
        margin-left: 3%;
        padding: 0;
        margin: 6px 0;
    }
    .cart-item {
        height: 100px;
        width: 100%;
    }
    .cart-content-top {
        align-items: center;
    }
    .cart-item-image {
        width: 65px;
        height: 65px;
        object-fit: cover;
        border-radius: 32.5px;
    }
}