.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 90%;
}

.about-content {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.img-banner {
    width: 50%;
    object-fit: cover;
}

.about-text {
    font-size: 16px;
    color: var(--text-color);
    margin: 20px;
    width: 60%;
    line-height: 2.0;

}

.about-images {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.preppy-img {
    width: 100px;
    margin: 20px;
}

@media (max-width: 768px) {
    .about-container {
        height: 90%;
        margin-top: 5vh;
        margin-bottom: 10vh;
    }

    .about-content {
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .img-banner {
        width: 100%;
        margin-top: 5vh;
    }

    .about-text {
        width: 90%;
    }

    .preppy-img {
        width: 50px;
    }
}