/* DRAWER COMPONENT */
.drawer-container {
    position: fixed;
    top: 15vh;
    left: 0;
    width: 250px;
    height: 90vh;
    z-index: 1000;
    transition: width 0.3s ease; /* Add transition for width change */
}

.drawer-btn {
    cursor: pointer;
    color: var(--text-color);
}

.drawer-container-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 10%;   
}

.drawer-container-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60%;
}

.drawer-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.drawer-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-bottom: 5vh;
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    transition: text-decoration 0.3s;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: var(--shadow);
}

.drawer-item:hover {
    background-color: var(--accent-color);
    transition: background-color 0.3s;
}

.drawer-icon:hover {
    padding-right: 25px;
    width: 200px;
    transform: translateX(50px); /* Move the item to the right by 50px */
    transition: width 0.5s, transform 0.5s;
}

@media screen and (max-width: 768px) {
    .drawer-container {
        display: none;
    }
}