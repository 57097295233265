
/* MAIN COMPONENT */
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10vh 0 10vh 0;
    height: 90vh;
    width: 85%;
    overflow: scroll;
    scrollbar-width: none;
    /*IE10+*/
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  
  .home-container::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
  }
  
  .home-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .home-title {
    font-size: 4rem;
    margin: 20px 0;
    color: var(--text-color);
  }
  
  .home-title-text-container {
    display: flex;
    flex-direction: row;
  }
  
  .header-logo {
    max-height: 7vh;
    object-fit: cover;
    background-color: var(--shadow);
    border-radius: 10px;
    border-width: 2px;
    border-color: var(--highlight);
    border-style: solid;
  }

  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
  
    .home-container {
        padding-top: 10vh;
        height: 90vh;
    }
  
    .home-title {
        font-size: 2.8rem;
    }
  
    .home-title-container {
        flex-direction: row;
        align-items: flex-start;
        align-items: center;
        justify-content: center;
    }
  
    .header-logo { 
        width: 60px;
        height: 60px;
    }
  }

  @media screen and (max-width: 768px){
    .home-container {
      align-items: center;
    }
    
  }