/* BANNER COMPONENT */
.banner-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 5vh;
    justify-content: center;
    background-color: var(--accent-color);
}

.banner-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

/* HEADER COMPONENT */
.header-container {
    top: 5vh;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 10vh;
    font-size: 14px;
    z-index: 100;
  }
  
.header-container-left {
    margin-left: 4vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 15%;
}

.header-container-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 15%;
    margin-right: 4vw;
}


.cart-btn {
    background-color: var(--shadow);
    color: #fff;
    border: none;
    border-radius: 5px;
    height: 40px;
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s;
    width: 92px;
  }
  
  .cart-btn:hover {
    background-color: var(--accent-color);
    transition: background-color 0.3s;
  }
  
  .cart-btn:active {
    transform: scale(0.95);
    transition: transform 0.3s;
  }
  
  .header-item {
    display: flex;
    margin-right: 18px;
    color: var(--text-color);
    text-decoration: none;
    transition: text-decoration 0.3s;
    font-size: 24px;
    font-weight: 700;
  }
  
  .header-item:hover {
      text-decoration: underline;
  }
  
  .header-button {
      cursor: pointer;
      padding-right: 4px;
  }

  .header-container-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

 /* FOOTER COMPONENT */
.footer-container {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5vh;
    background-color: rgba(0, 0, 0, 0.12);
    font-size: 14px;
    z-index: 100;
}

.footer-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 15%;
    margin-left: 5vw;
}

.footer-container-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: calc(5vw - 18px);
    width: 15%;
}

.footer-item {
    margin-right: 18px;
    color: var(--item-color);
    text-decoration: none;
    transition: text-decoration 0.3s;
}

.footer-item:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer-container {
        display: flex;

        flex-direction: row;
        position: fixed;
        bottom: 0;
        align-items: center;
        height: auto;
        width: 90vw;
        justify-content: space-between;
        width: 100%;
        height: 5vh;
        background-color: rgba(0, 0, 0, 0.12);
        font-size: 14px;
    }
}

/* HERO PANEL */
.hero-panel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
    height: 100%;
    background-color: #fff  ;
    color: var(--text-color);
    font-size: 2rem;
    text-align: center;
    border-radius: 25px;
    margin: 5vh 5vh 0 0;
    padding: 15px 10px;
}

.hero-panel-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 30px;
}

.hero-panel-image-container {
    flex: 1;
    overflow: hidden;
}

.hero-panel-image {
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.hero-panel-title {
    font-size: 4rem;
    margin: 20px 0;
    color: var(--text-color);
}

.hero-panel-button {
    animation: bounce 1.5s infinite;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
/*  remove bounce animation on hover */
.hero-panel-button:hover {
    animation: none;
    background-color: var(--accent-color) !important;
    transition: background-color 0.3s ;
}

@media (max-width: 768px) {
    .hero-panel-container {
        flex-direction: column;
        justify-content: center;
        margin: 0;
        
    }
    .hero-panel-image-container {
        flex: none;
    }
    .hero-panel-content {
        align-items: center;
        text-align: center !important;
        padding: 0;
    }
    .hero-panel-title {
        font-size: 4rem;
        margin: 20px 0;
        color: var(--text-color);
    }
}